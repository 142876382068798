import React, { useState } from "react"
import { graphql } from 'gatsby'

import LazySizes from "../components/layout/lazySizes"
import SEO from "../components/layout/seo"
import '../components/pages/news/news.scss'

import Transition from '../components/transition/transition'
// const return date

export default ({ data }) => {

  const allNews = data.wpgraphql.pages.nodes[0].newsPage.entries;
  const current = allNews[0];
  const [ state, setState ] = useState({
    displayedImagedImage: false,
    // news: allNews
    news: allNews.filter((element, index) => index > 0)
  })

  const handleMouseOver = (image) => {
    // console.log(image)
    setState({
      ...state,
      displayedImagedImage: image
    })

  }

  return (
    <div className="page__news" >
    
      <SEO title="News" />
      
      <Transition>
        <div className="news__current">
            <div className="news__display">
              <div>
                <LazySizes image={current.image} proportional={true} />
              </div>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{__html: `${current.info.text}`}} />
              {current.info.year}
            </div>
          </div>
        <div className="news">
          <div className="news__display">
          {
            state.displayedImagedImage ?
            <div>
              <LazySizes image={state.displayedImagedImage} proportional={true} />
            </div>
            : false
          }
          </div>
          <div className="news__list">
            
            <div>
            {
              state.news.map((entry, i) => {
                
                const image = entry.image

                if(!image) return false

                const date = entry.info.date[0]

                let dateMonth = ""
                let dateDay = ""

                if(date.__typename === "WPGraphQL_Page_Newspage_Entries_Entry_Info_Date_Single"){

                  const day = new Date(date.day)

                  dateMonth = day.getMonth()+1
                  dateDay = day.getDate()
                  // ${date.endTime} - ${date.startTime}
                }

                return(
                  <div key={i} className="news__list__item" 
                  onMouseOver={() => handleMouseOver(entry.image)} 
                  onFocus={() => handleMouseOver(entry.image)} 
                  tabIndex={0} 
                  role="button"
                  >
                    <div>
                      <div>
                        {entry.info.year.toString().substring(2)}
                      </div>
                      <div dangerouslySetInnerHTML={{__html: dateMonth}} />
                      <div dangerouslySetInnerHTML={{__html: dateDay}} />
                    </div>
                      {
                        entry.info.link ?
                      <div>
                        <a href={entry.info.link} >{entry.info.text}</a>
                      </div>  
                      :
                      <div dangerouslySetInnerHTML={{__html: entry.info.text}} />
                      }
                  </div>
                )
              })
            }
            </div>
        </div>  
      </div>  
      </Transition>
    </div>
  )
}

export const newsQuery = graphql`
  query newsQuery {
    wpgraphql{
      pages(where: {name: "news"}) {
        nodes{
          title(format: RENDERED)
          slug
          newsPage{
            entries {
              ... on WPGraphQL_Page_Newspage_Entries_Entry {
                info{
                  text
                  date {
                    __typename
                    ... on WPGraphQL_Page_Newspage_Entries_Entry_Info_Date_Single{
                      day
                      endTime
                      startTime
                    }
                    ... on WPGraphQL_Page_Newspage_Entries_Entry_Info_Date_Span {
                      end
                      endTime
                      start
                      startTime
                    }
                  }
                  year
                }
                image{
                  mediaDetails{
                    width
                    height
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
// export const newsQuery = graphql`
//   query newsQuery {
//     wordpressPage(slug: {eq: "news"}) {
//       title
//       slug
//       path
//       newsPage{
//         entriesPage {
//           ... on WordPressnewsPage_entry {
//             id
//             info{
//               text
//               date {
//                 newsPage_fc_layout
//                 start
//                 end
//                 start_time
//                 end_time
//               }
//               year(locale: "Y")
//             }
//             image{
//               localFile {
//                 childImageSharp {
//                   fluid(maxWidth: 1920){
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `